.disabled-row input, 
.disabled-row select,
.disabled-row textarea,
.disabled-row button,
.disabled-row [role="textbox"], /* Material-UI TextFieldなどをカバー */
.disabled-row [tabindex="0"] {
  /* タブ操作からも除外 */
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.6; /* 無効状態を視覚的にわかりやすく */
}
